<template>
  <div class="">
    <div v-if="hasDeliveryMethods">
      <delivery-method
        v-for="(item, index) in formDeliveryMethods"
        :key="item.method.id + index"
        ref="delivery-method"
        :items="deliveryMethods"
        :is-methods-loading="deliveryMethodsLoading"
        :method="item"
        :property_id = propertyId
        :view-only="viewOnly"
        @clearMethod="clearDeliveryMethod(index)"
        @saveMethod="saveMethod($event, index)"
      />
    </div>
    <span v-if="formDeliveryMethods.length < 1">
      <hb-link v-if="!viewOnly" @click="addDeliveryMethod()" text>
        {{ methodText }}
      </hb-link>
    </span>
    <!-- remove span to support multiple delivery methods -->
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { mapGetters, mapActions } from "vuex";
import { notificationMixin } from "@/mixins/notificationMixin.js";

import DeliveryMethod from "./DeliveryMethod.vue";
import MergeFields from "../../includes/MergeFields";

export default {
  name: "DeliveryMethods",
  mixins: [notificationMixin],
  components: {
    DeliveryMethod,
    MergeFields,
  },
  props: {
    settings: {
      type: Array,
      default: () => [],
    },
    propertyId: {
      type: String,
      default: "",
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      deliveryMethods: [],
      deliveryMethodsLoading: true,
      deliveryMethodEmptyText: "",
      hasDuplicateError: false,
      defaultDeliveryMethod: {
        method: {
          id: "",
          key: "",
        },
        active: true,
        subject: "",
        message: "[Tenant.FirstName] [Tenant.LastName]",
      },
      initalformDeliveryMethods: cloneDeep(this.settings),
      formDeliveryMethods: cloneDeep(this.settings),
      showMergeFields: false,
    };
  },
  watch: {
    formDeliveryMethods: {
      immediate: true,
      handler(methods) {
        if (this.formDeliveryMethods && this.formDeliveryMethods.length) {
          this.$emit("updatedSettings", methods);
        }
      },
      deep: true,
    },
    propertyId: {
      handler() {
        this.formDeliveryMethods = [];
        this.hasDuplicateError = false;
        this.initalformDeliveryMethods = [];
      },
    },
  },
  async mounted() {
    await this.getDeliveryMethodsData();
  },
  computed: {
    ...mapGetters({
      getDeliveryMethods: "documentsStore/getDeliveryMethods",
    }),
    methodText() {
      return this.formDeliveryMethods && this.formDeliveryMethods.length
        ? "+Add Additional Delivery Method"
        : "+Add Delivery Method";
    },
    hasDeliveryMethods() {
      return this.formDeliveryMethods?.length ? true : false;
    },
  },
  beforeDestroy() {
    this.errors.items = [];
  },
  methods: {
    ...mapActions({
      fetchDeliveryMethodsData: "documentsStore/fetchDeliveryMethods",
    }),

    async saveMethod(data, index) {
      this.formDeliveryMethods[index] = data;
      this.$emit("updatedSettings", this.formDeliveryMethods);
    },

    resetDeliveryMethods() {
      this.$nextTick(() => {
        this.formDeliveryMethods = cloneDeep(this.initalformDeliveryMethods);
        this.formDeliveryMethods.forEach((method, index) => {
          if (this.$refs["delivery-method"])
            this.$refs["delivery-method"][index]?.resetDeliveryMethod();
        });
      });
    },

    saveDeliveryMethods() {
      this.initalformDeliveryMethods = cloneDeep(this.formDeliveryMethods);
      this.formDeliveryMethods.forEach((method, index) => {
        if (this.$refs["delivery-method"])
          this.$refs["delivery-method"][index].saveDeliveryMethod();
      });
    },

    hasEmailContents(id) {
      return ["standard_email", "registered_email"].includes(
        this.deliveryMethods.find((item) => item.method.id === id)?.gds_key
      );
    },

    /**
     * Funtion to get document-delivery methods
     */
    async getDeliveryMethodsData() {
      this.deliveryMethodsLoading = true;
        try {
          await this.fetchDeliveryMethodsData(this.propertyId);
          this.deliveryMethods = (await this.getDeliveryMethods) ?? [];
        } catch (err) {
          console.log(err, "Unable to fetch Delivery Methods");
        }
      this.deliveryMethodsLoading = false;
    },

    addDeliveryMethod() {
      let defaultData = cloneDeep(this.defaultDeliveryMethod);
      this.formDeliveryMethods.push(defaultData);
    },

    clearDeliveryMethod(index) {
      this.$nextTick(() => {
        this.formDeliveryMethods.splice(index, 1);
      });
    },

    async validateFields() {
      let duplicateErrorMsg = {
        message: "Delivery Methods cannot be Duplicate.",
        id: 476,
      };

      let entries = [];
      this.formDeliveryMethods.forEach((item, index) => {
        if (!entries.some((arrItem) => arrItem.method.id == item.method.id)) {
          entries.push(item);
          this.$refs[`delivery-method`][index]?.setDuplicateError(false);
        } else {
          this.errors.items.splice(
            this.errors.items.indexOf(duplicateErrorMsg),
            1
          );
          this.$refs[`delivery-method`][index]?.setDuplicateError(true);
          this.hasDuplicateError = true;
          this.errors.items.push(duplicateErrorMsg);
        }
      });
      if (entries.length === this.formDeliveryMethods.length) {
        this.hasDuplicateError = false;
        this.errors.items.splice(
          this.errors.items.indexOf(duplicateErrorMsg),
          1
        );
      }

      for (let index = 0; index < this.formDeliveryMethods.length; index++) {
        let valid = await this.$refs[`delivery-method`][
          index
        ]?.$validator?.validateAll();
        if (!valid || this.hasDuplicateError) {
          return false;
        } else {
          continue;
        }
      }
      return true;
    },
  },
};
</script>

<style scoped lang="scss">
.delivery-loader {
  width: 110%;
}
</style>
